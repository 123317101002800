import { CssBaseline, PaletteRange, extendTheme } from '@mui/joy'
import { Session } from 'next-auth'
import { SessionProvider } from 'next-auth/react'
import type { AppProps } from 'next/app'
import localFont from 'next/font/local'
import {
  experimental_extendTheme as materialExtendTheme,
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  THEME_ID as MATERIAL_THEME_ID,
} from '@mui/material/styles'
import { CssVarsProvider as JoyCssVarsProvider } from '@mui/joy/styles'
import { LicenseInfo } from '@mui/x-date-pickers-pro'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

import '../global.css'

import { datadogRum } from '@datadog/browser-rum'
import Head from 'next/head'

datadogRum.init({
  applicationId: process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID || '',
  clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN || '',
  site: 'datadoghq.com',
  service: 'hedgerow',
  env: process.env.NEXT_PUBLIC_VERCEL_ENV || 'dev',
  version: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
  allowedTracingUrls: [
    (url) =>
      url.startsWith(
        process.env.NEXT_PUBLIC_VERCEL_URL || 'http://localhost:3000'
      ),
  ],
})

datadogRum.startSessionReplayRecording()

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Atlantic/Reykjavik') // GMT UTC 0
dayjs.locale('en')

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
LicenseInfo.setLicenseKey(process.env.NEXT_PUBLIC_MUI_LICENSE_KEY!)

const materialTheme = materialExtendTheme()

declare module '@mui/joy/styles' {
  interface ColorPalettePropOverrides {
    // apply to all Joy UI components that support `color` prop
    primary: true
    secondary: true
  }

  interface Palette {
    primary: PaletteRange & {
      onPrimary: string
    }
    secondary: PaletteRange & {
      onSecondary: string
    }
  }
}

const Audrey = localFont({
  src: '../../public/fonts/Audrey-Normal.otf',
  weight: '400',
})
const Avenir = localFont({
  src: '../../public/fonts/Avenir-Regular.ttf',
  weight: '400',
})

const avenirTypographyBase = {
  fontFamily: Avenir.style.fontFamily,
  fontStyle: Avenir.style.fontStyle,
  fontWeight: Avenir.style.fontWeight,
}

const audreyTypograhyBase = {
  fontFamily: Audrey.style.fontFamily,
  fontStyle: Audrey.style.fontStyle,
  fontWeight: Audrey.style.fontWeight,
}

const theme = extendTheme({
  typography: {
    body1: {
      ...avenirTypographyBase,
      fontSize: '16px',
    },
    body2: {
      ...avenirTypographyBase,
      fontSize: '14px',
    },
    body4: {
      ...avenirTypographyBase,
      fontSize: '13px',
      letterSpacing: '1px',
    },
    body5: {
      ...avenirTypographyBase,
      fontSize: '10px',
      letterSpacing: '1px',
    },
    h1: {
      ...audreyTypograhyBase,
      fontSize: '38px',
      letterSpacing: '1px',
      textTransform: 'uppercase',
    },
    h2: {
      ...audreyTypograhyBase,
      fontSize: '28px',
      letterSpacing: '3px',
      textTransform: 'uppercase',
    },
    h3: {
      ...audreyTypograhyBase,
      fontSize: '24px',
      letterSpacing: '3px',
    },
    h4: {
      ...audreyTypograhyBase,
      fontSize: '18px',
      letterSpacing: '2px',
      textTransform: 'uppercase',
    },
    h5: {
      ...avenirTypographyBase,
      fontSize: '12px',
      letterSpacing: '1px',
      textTransform: 'uppercase',
    },
  },
  colorSchemes: {
    light: {
      palette: {
        primary: {
          '50': '#eceef4',
          '100': '#d0d5df',
          '200': '#b3b9c8',
          '300': '#969db0',
          '400': '#6a748d',
          '500': '#5C667C', // 500 is main channel
          '600': '#4b5365',
          '700': '#3b4150',
          '800': '#282d38',
          '900': '#1C1F26',
          onPrimary: '#fff',
        },
        secondary: {
          '50': '#ccc6c6',
          '100': '#ccc6c6',
          '200': '#ad9fa0',
          '300': '#8f797a',
          '400': '#7a5d5e',
          '500': '#654244', // 500 is main channel
          '600': '#5a3a3c',
          '700': '#4a2f32',
          '800': '#3b2428',
          '900': '#2b191c',
          onSecondary: '#fff',
        },
        neutral: {
          '50': '#F8F8F9',
          '100': '#E8E9EA',
          '200': '#A3A6AC',
          '300': '#8B9097',
          '400': '#747A82',
          '500': '#5D646D',
          '600': '#464D59',
          '700': '#2F3744',
          '800': '#18212F',
          '900': '#111721',
        },
        danger: {
          '50': '#FDEFEB',
          '100': '#FBDED6',
          '200': '#F7BDAD',
          '300': '#F49D85',
          '400': '#F07C5C',
          '500': '#EC5B33',
          '600': '#BD4929',
          '700': '#8E371F',
          '800': '#5E2414',
          '900': '#2F120A',
        },
        info: {
          '50': '#ECF0F7',
          '100': '#D8E1EF',
          '200': '#B2C3DF',
          '300': '#8BA5D0',
          '400': '#6587C0',
          '500': '#3E69B0',
          '600': '#2B4A7B',
          '700': '#1F3558',
          '800': '#192A46',
          '900': '#18212F',
        },
        success: {
          '50': '#EEF2EF',
          '100': '#DCE5DF',
          '200': '#B9CCBE',
          '300': '#97B29E',
          '400': '#74997D',
          '500': '#517F5D',
          '600': '#41664A',
          '700': '#314C38',
          '800': '#203325',
          '900': '#101913',
        },
        warning: {
          '50': '#FDF9EC',
          '100': '#FBF4D9',
          '200': '#F6E8B4',
          '300': '#F2DD8E',
          '400': '#EDD169',
          '500': '#E9C643',
          '600': '#BA9E36',
          '700': '#8C7728',
          '800': '#5D4F1B',
          '900': '#2F280D',
        },
        text: {
          primary: '#3D3D3A',
        },
        common: {
          black: '#3D3D3A',
          white: '#ffffff',
        },
      },
    },
  },
  components: {
    JoyModalDialog: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
    JoyCard: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          backgroundColor: '#EBEDED',
          borderRadius: 0,
        },
      },
    },
    JoyIconButton: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    JoyFormLabel: {
      styleOverrides: {
        root: {
          fontFamily: Avenir.style.fontFamily,
          fontStyle: Avenir.style.fontStyle,
          fontWeight: Avenir.style.fontWeight,
          fontSize: '14px',
        },
      },
    },
    JoyInput: {
      styleOverrides: {
        root: {
          height: 56,
          fontFamily: Avenir.style.fontFamily,
          fontStyle: Avenir.style.fontStyle,
          fontWeight: Avenir.style.fontWeight,
          fontSize: '18px',
          letterSpacing: '1px',
          borderRadius: 0,
          width: '100%',
          textTransform: 'uppercase',
        },
      },
    },
    JoyButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          height: 56,
          fontFamily: Audrey.style.fontFamily,
          fontStyle: Audrey.style.fontStyle,
          fontWeight: Audrey.style.fontWeight,
          fontSize: 18,
          letterSpacing: '1px',
          borderRadius: 0,
          textTransform: 'uppercase',

          ...(ownerState.size === 'sm' && {
            fontSize: 12,
            fontFamily: Avenir.style.fontFamily,
            fontStyle: Avenir.style.fontStyle,
            fontWeight: Avenir.style.fontWeight,
          }),

          ...(ownerState.size === 'md' && {
            fontSize: 16,
            fontFamily: Audrey.style.fontFamily,
            fontStyle: Audrey.style.fontStyle,
            fontWeight: Audrey.style.fontWeight,
          }),

          ...(ownerState.size === 'lg' && {
            fontFamily: Audrey.style.fontFamily,
            fontStyle: Audrey.style.fontStyle,
            fontWeight: Audrey.style.fontWeight,
          }),
        }),
      },
    },
    JoyMenu: {
      styleOverrides: {
        root: () => ({
          borderRadius: 0,
        }),
      },
    },
  },
})

export default function App({
  Component,
  pageProps: { session, ...pageProps },
}: AppProps & {
  session: Session | null | undefined
}) {
  return (
    <>
      <Head>
        <title>Hedgerow Exclusive</title>
        <meta name="description" content="Hedgerow rentals for offer" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <MaterialCssVarsProvider
        theme={{ [MATERIAL_THEME_ID]: materialTheme }}
        defaultMode="light"
      >
        <JoyCssVarsProvider
          modeStorageKey="basta-hedgerow-mode"
          defaultMode="light"
          theme={theme}
        >
          <CssBaseline />

          <SessionProvider session={session}>
            <Component {...pageProps} />
          </SessionProvider>
        </JoyCssVarsProvider>
      </MaterialCssVarsProvider>
    </>
  )
}
